.marker-btn {
  background: none;
  border: none;
  cursor: pointer;
}

.marker-btn img {
  width: 20px;
  height: 20px;
}
.mapContainer {
  width: 100vw;
  height: 90vh;
}
@media only screen and (max-width: 375px) {
  .container {
    width: 100%;
    height: 100%;
  }
  .mapContainer {
    height: 30vh;
  }
}

@media only screen and (max-width: 375px) {
  .container {
    width: 100%;
    height: 100%;
  }
  .mapContainer {
    width: 100vw;
    height: 30vh;
  }
}
@media only screen and (max-width: 414px) {
  .container {
    width: 100%;
    height: 100%;
  }
  .mapContainer {
    width: 100vw;
    height: 30vh;
  }
}
@media only screen and (max-width: 812px) {
  .container {
    width: 100%;
    height: 100%;
  }
  .mapContainer {
    width: 100vw;
    height: 30vh;
    position: sticky;
    top: 10vh;
    grid-row: 1;
  }
}
