.navbar {
  height: 10vh;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 999;
  min-height: 10vh;
  background-color: #050f13;
  margin: auto;
  justify-content: space-between;
  padding: 1rem 8rem;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  @media (max-width: 812px) {
    padding: 1rem;
  }
}

.navbar-container {
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-android {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(7, auto);
  grid-gap: 2px;
  align-self: flex-end;
  list-style: none;
  text-align: center;
  justify-content: end;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-family: "Lato";
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #459fbf;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .navbar {
    padding: 1rem 1rem;
  }
  .navbar-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    display: flex;
    align-items: center;
  }

  .menu-icon {
    display: block;

    font-size: 1.5rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .styled_div {
    display: flex;
    height: 100%;
    justify-content: space-around;
    align-items: center;

    @media (max-width: 812px) {
      display: none;
    }
  }

  .styled_div a {
    color: #b5b5b5;
    text-decoration: none;
    margin-left: 3rem;
    cursor: pointer;
    /*hover {
        color: #459FBF;
    }*/
  }
}
@media only screen and (max-width: 1080px) {
  .navbar {
    padding: 1rem 2rem;
  }
}

@media only screen and (min-widht: 320px) {
  .navbar-logo img {
    max-width: 60%;
  }
}
