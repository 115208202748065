:root {
  --Background: #050f13;
}

body {
  background-color: #050f13;
  font-family: "lato";
  overflow-x: hidden;
}
h1 {
  font-family: "futura";
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  @media (max-width: 812px) {
    font-size: 2rem;
  }
}
h2 {
  font-family: "lato";
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  color: white;
  @media (max-width: 812px) {
    font-size: 1.25rem;
  }
}
h3 {
  font-family: "Futura";
  font-size: 1.25rem;
  font-weight: 300;
  color: white;
  @media (max-width: 812px) {
    font-size: 1.25rem;
  }
}
p {
  font-size: 1.125rem;
  font-weight: 400;
  font-family: "Lato";
  color: #dbdbdb;
}
.languages {
  font-size: 0.9rem;
  font-weight: 400;
  display: inline-flex;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.foundation,
.targetphases,
.press,
.whitepapers,
.map {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

/*.challenge {
  background-image: url('/src/resources/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}*/

/* home section styles */
.columnLayout {
  position: relative;
  max-width: 60%;
}
.pieShadow {
  -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

.polluted {
  padding: 5rem 1rem;
  position: relative;
  margin-left: -70px;
}

.home {
  padding: 8rem 8rem;
  height: 90vh;
}

h1 {
  margin-bottom: 3rem;
}

.blockChainSVG {
  position: absolute;
  right: 6rem;
  top: 15rem;
}

/* Challange section styles */
.challenge {
  height: 100%;
}
.problem {
  padding: 4rem 8rem;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.container {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.solution {
  height: 100vh;
  background: linear-gradient(
    273.12deg,
    #050f13 2.59%,
    #09181e 66.58%,
    #0d232c 72.35%,
    #163a48 78.98%,
    #1c485a 82.59%,
    #2d7490 90.81%,
    #3e9ec4 97.41%
  );
}
.solutionbox {
  padding: 4rem 8rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.solutionbox h2 {
  margin-top: 3rem;
  max-width: 100vh;
}
.gif {
  max-width: 500px;
  border-radius: 20px;
  margin-top: 1rem;
}

/* Distribution section styles */

.foundationBox {
  padding: 4rem 8rem;
  display: flex;
  flex-direction: column;
}
.distributionBox {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.cards {
  display: grid;
  grid-template-rows: 1fr auto auto;
  grid-row-gap: 1rem;
  width: 90%;
}
.cards h3 {
  margin-bottom: 1rem;
}
.cardBlue {
  position: relative;
  background-color: var(--Background);
  box-shadow: inset 0px 4px 16px 8px rgba(60, 146, 177, 0.15),
    inset 0px 4px 8px rgba(174, 174, 174, 0.25);
  border-radius: 20px;
  padding: 2rem;
}
.phases .cardBlue {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: var(--Background);
  box-shadow: inset 0px 4px 16px 8px rgba(60, 146, 177, 0.15),
    inset 0px 4px 8px rgba(174, 174, 174, 0.25);
  border-radius: 20px;
  padding: 2rem;
}
.cardBlue img {
  margin-right: 2rem;
  width: 4rem;
}

.cardBlue::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6px;
  background: linear-gradient(
    180deg,
    rgba(37, 156, 211, 0) 13.76%,
    #259cd3 50.34%,
    rgba(37, 156, 211, 0) 86.91%
  );
}
.cardRed {
  position: relative;
  background-color: var(--Background);
  box-shadow: inset 0px 4px 16px 8px rgba(60, 146, 177, 0.15),
    inset 0px 4px 8px rgba(174, 174, 174, 0.25);
  border-radius: 20px;
  padding: 2rem;
}

.cardRed::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6px;
  background: linear-gradient(
    180deg,
    rgba(203, 37, 37, 0) 0%,
    #d11f1f 50%,
    rgba(208, 33, 32, 0) 100%
  );
}
.cardYellow {
  position: relative;
  background-color: var(--Background);
  box-shadow: inset 0px 4px 16px 8px rgba(60, 146, 177, 0.15),
    inset 0px 4px 8px rgba(174, 174, 174, 0.25);
  border-radius: 20px;
  padding: 2rem;
}

.cardYellow::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 6px;
  background: linear-gradient(
    180deg,
    rgba(191, 184, 41, 0) 0%,
    #d1c021 50%,
    rgba(209, 191, 33, 0) 100%
  );
}
.card ul {
  margin-left: 1rem;
}
ul .darkBlue::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #006d95; /* Change the color */
  font-size: 24px;
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
ul .blue::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #459fbf; /* Change the color */
  font-size: 24px;
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}
ul .white::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: white; /* Change the color */
  font-size: 24px;
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

li span {
  color: white;
}
li {
  font-size: 1.125rem;
  font-weight: 400;
  font-family: "Lato";
  color: #b1b1b1;
  list-style: none;
}
.imgContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* TargetPhases section styles */
.phasesBox {
  padding: 4rem 8rem;
}
.phases {
  display: grid;
  grid-template: "1fr 1fr" "1fr 1fr";
  grid-gap: 1rem;
}
.phases h3 {
  margin-bottom: 1rem;
}
/* Whitepapers section styles */
.papersBox {
  height: 100%;
  padding: 4rem 8rem;
}
.content {
  display: grid;
  grid-template-columns: 1fr 0.6fr;
  padding: 2rem;
  border-radius: 20px;
  box-shadow: inset 0px 4px 16px 8px rgba(60, 146, 177, 0.15),
    inset 0px 4px 8px rgba(174, 174, 174, 0.25);
}
.content p {
  margin-top: 1rem;
}
.content .whiteBody {
  margin-top: 0rem;
}
.content span {
  color: #3c92b1;
}
.downloadPapers {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.downloadPapers img {
  margin-bottom: 1rem;
}
/* contact section styles */
.background {
  height: 90vh;
  background-image: url("./resources/images/sea_background.jpg");
  background-size: cover;
  padding: 4rem 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0px 4px 16px 8px rgba(60, 146, 177, 0.15),
    inset 0px 4px 8px rgba(174, 174, 174, 0.25);
}
.contactContainer {
  width: 90%;
  backdrop-filter: blur(10px);
  box-shadow: 0px -4px 16px 8px rgba(60, 146, 177, 0.1),
    0px 4px 16px 8px rgba(174, 174, 174, 0.25);
  display: grid;
  grid-template-columns: 0.4fr 0.6fr;
  padding: 1rem;
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 20px;
}
.contactInformation {
  background: #050f13;
  box-shadow: inset 0px 4px 16px 8px rgba(60, 146, 177, 0.15),
    inset 0px 4px 8px rgba(174, 174, 174, 0.25);
  border-radius: 20px;
  padding: 2rem;
}
.contactForm {
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
}
.formInput {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.formInput label {
  font-size: 0.75rem;
  margin-bottom: 0rem;
  color: var(--Background);
}
.formInput input {
  outline: none;
  padding: 1rem;
  border: none;
  background: transparent;
  border-bottom: solid 2px var(--Background);
  font-size: 1.125rem;
  color: black;
  font-family: "Lato";
}
.formInput textarea {
  outline: none;
  padding: 1rem;
  border: none;
  background: transparent;
  border-bottom: solid 2px var(--Background);
  font-size: 1.125rem;
  color: black;
  font-family: "Lato";
}
@media only screen and (max-width: 812px) {
  /* home section styles */
  .home {
    height: 100%;
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
  }
  .columnLayout {
    position: relative;
    max-width: 100%;
  }
  h1 {
    margin-bottom: 2rem;
    font-size: 2.5rem;
  }
  h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  .blockChainSVG {
    position: unset;

    width: 100%;
    overflow: hidden;
  }
  .polluted {
    padding: 4rem 1rem 0rem 1rem;
    position: relative;
    margin-left: 0px;
  }
  /* Challange section styles */
  .challenge {
    height: 100%;
  }
  .problem {
    padding: 4rem 1rem;
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
    height: 100%;
  }
  .container {
    padding: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .theproblemPNG {
    width: 100%;
  }
  .solutionbox {
    padding: 1rem;
  }
  /* Foundation section styles */

  .foundationBox {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
  }
  .distributionBox {
    display: grid;
    grid-template-columns: unset;
  }
  .cards {
    display: grid;
    grid-template-rows: 1fr auto auto;
    grid-row-gap: 1rem;
    width: 100%;
  }
  .imgContainer {
    margin-top: 2rem;
  }
  /* TargetPhases section styles */
  .phasesBox {
    padding: 4rem 1rem;
  }
  .phases {
    display: grid;
    grid-template: unset;
    grid-gap: 1rem;
  }
  /* Whitepapers section styles */
  .papersBox {
    height: 90vh;
    padding: 4rem 1rem;
  }
  .content {
    display: grid;
    grid-template-columns: unset;
    padding: 2rem;
  }
  .content p {
    display: none;
  }
  .content .whiteBody {
    margin-top: 0rem;
    display: initial;
  }
  .downloadPapers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .downloadPapers img {
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
  /* contact section styles */
  .background {
    padding: 4rem 1rem;
    height: 100%;
  }
  .contactContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /* Note: backdrop-filter has minimal browser support */
  }
  .contactInformation {
    padding: 1rem;
    width: 100%;
  }
  .contactForm {
    padding: 2rem 1rem;
  }
  .formInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .formInput label {
    font-size: 0.75rem;
    margin-bottom: 0rem;
    color: var(--Background);
  }
  .formInput input {
    outline: none;
    padding: 1rem;
    border: none;
    background: transparent;
    border-bottom: solid 2px var(--Background);
    font-size: 1.125rem;
    color: black;
    font-family: "Lato";
  }
  .formInput textarea {
    outline: none;
    padding: 1rem;
    border: none;
    background: transparent;
    border-bottom: solid 2px var(--Background);
    font-size: 1.125rem;
    color: black;
    font-family: "Lato";
  }
}
.mapview {
  overflow-y: hidden;
}

@media only screen and (min-height: 850px) and (min-width: 812px) {
  .blockChainSVG {
    width: 50%;
  }
  .mapview {
    height: 90vh;
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
  }
}

@media only screen and (max-width: 1280px) {
  /* home section styles */
  .home {
    padding: 4rem 8rem;
    height: 100%;
  }
  .blockChainSVG {
    width: 50%;
  }
  /* contact section styles */
}

@media only screen and (max-width: 1080px) {
  /* home section styles */
  .home {
    padding: 4rem 2rem;
    height: 100%;
  }
  .blockChainSVG {
    width: 50%;
  }
  .problem {
    padding: 3rem 2rem;
    height: 100%;
  }
  .theproblemPNG {
    width: 80%;
  }
  .solutionbox {
    padding: 3rem 2rem;
  }
  .foundationBox {
    padding: 3rem 2rem;
  }
  .phasesBox {
    padding: 3rem 2rem;
  }
  .papersBox {
    padding: 3rem 2rem;
  }
  .background {
    padding: 4rem 2rem;
  }
}
@media only screen and (max-width: 800px) {
  .blockChainSVG {
    width: 100%;
  }
  .theproblemPNG {
    width: 80%;
  }
}
@media only screen and (max-width: 1280px) {
  .theproblemPNG {
    width: 100%;
  }
  .mapview {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
  }
}
@media only screen and (max-width: 414px) {
  /* home section styles */
  .home {
    padding: 3rem 1rem;
    height: 100%;
  }
  .blockChainSVG {
    width: 100%;
    max-height: 35vh;
  }
  .problem {
    padding: 3rem 1rem;
    height: 100%;
  }
  .solutionbox {
    padding: 3rem 1rem;
  }
  .foundationBox {
    padding: 3rem 1rem;
  }
  .phasesBox {
    padding: 3rem 1rem;
  }
  .papersBox {
    padding: 3rem 1rem;
  }

  /* contact section styles */
  .background {
    padding: 4rem 1rem;
  }
  .contactContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /* Note: backdrop-filter has minimal browser support */
  }
  .contactInformation {
    padding: 1rem;
    width: 100%;
  }
  .contactForm {
    padding: 2rem 1rem;
  }
  .formInput {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .formInput label {
    font-size: 0.75rem;
    margin-bottom: 0rem;
    color: var(--Background);
  }
  .formInput input {
    outline: none;
    padding: 1rem;
    border: none;
    background: transparent;
    border-bottom: solid 2px var(--Background);
    font-size: 1.125rem;
    color: black;
    font-family: "Lato";
  }
  .formInput textarea {
    outline: none;
    padding: 1rem;
    border: none;
    background: transparent;
    border-bottom: solid 2px var(--Background);
    font-size: 1.125rem;
    color: black;
    font-family: "Lato";
  }
  .gif {
    max-width: 300px;
  }
}
@media only screen and (min-width: 1280px) {
  /* home section styles */
  .home {
    padding: 4rem 8rem;
    height: 100%;
  }
  .blockChainSVG {
    width: 50%;
    position: absolute;
    right: 6rem;
    bottom: 2rem;
  }
}
.mapview {
  height: 90vh;
  display: grid;
  grid-template-columns: 30% 70%;
  overflow-y: visible;
}

@media only screen and (max-width: 375px) {
  .mapview {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-flow: column-reverse;
  }
  .map {
    flex-grow: 2;
    height: 40%;
  }
}
@media only screen and (max-height: 1024px) and (max-width: 768px) {
  .mapview {
    height: 90vh;
    display: grid;
    grid-template-columns: unset;
    grid-template-rows: 0.3fr 0.7fr;
  }
  .chart {
    grid-row: 2;
  }
}
