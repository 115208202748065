.styled_card {
    margin-top: 2rem;
    position: relative;
    background-color: var(--Background);
    box-shadow: inset 0px 4px 16px 8px rgba(60, 146, 177, 0.15), inset 0px 4px 8px rgba(174, 174, 174, 0.25);
    border-radius: 20px;
    padding: 2rem;
  }
  
  .styled_card  p {
      font-size: 24px;
      font-family: 'Futura';
      color: white;
  }
  
  .styled_card  p::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 6px;
    background: linear-gradient(180deg, rgba(37, 156, 211, 0) 13.76%, #259CD3 50.34%, rgba(37, 156, 211, 0) 86.91%);
  }