.sidebar {
  display: inline-block;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: #070f12;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.4);
  @media (max-width: 812px) {
    padding: 1rem;
    height: 100%;
  }
}
.sidebar_list {
  height: 100%;
  padding: 0;
  width: 100%;
  overflow: auto;
}

.sidebar_list {
  display: flex;
  flex-direction: column;
  padding-left: 8px;
  padding-right: 8px;
}

.sidebar_list .sidebar_listrow {
  width: 100%;
  height: 100%;
  border: 1px solid #071014;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  color: #5291ae;
  justify-content: center;
  background-color: #194054;
  margin: 0;
  padding: 24px;
  font: inherit;
  vertical-align: baseline;
  border-radius: 10px;
  margin-bottom: 8px;
}

.sidebar_list .sidebar_listrow:hover {
  cursor: pointer;
  background-color: #1f4a61;
}

.sidemenu {
  height: 100%;
  display: grid;
  grid-template-rows: 0.2fr 1fr;
  box-shadow: 10px 0px 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
  overflow: scroll;
  font-family: "Lato";
}

.titlerow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  height: 100%;
  background: transparent;
  backdrop-filter: blur(10px);
}
.titlerow h1 {
  margin: 0rem;
}
.titlerow h3 {
  font-size: 2.25rem;
  font-family: "Futura";
  font-weight: bold;
}

.titlerow #count {
  margin-right: 1.5rem;
  color: #4f9c84;
  font-family: "lato";
  font-size: 3.75rem;
}
.titlerow #subtitle {
  flex: 70%;
  display: grid;
  place-items: bottom;
  font-family: "lato";
}

.location {
  font-size: 12px;
  font-family: "Lato";
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}

.location .address {
  font-size: 12px;
  color: #5291ae;
}
.location .icon {
  font-size: 12px;
  color: #5291ae;
}
.name {
  font-size: large;
  color: white;
  margin-bottom: 8px;
  font-family: "Lato";
}
.info {
  font-size: 12px;
  font-family: "Lato";
  margin-left: 8px;
}
.details_menu {
  flex-direction: column;
  padding: 8px 24px 16px 24px;
  font-family: "Lato";
}
.backContainer {
  width: 100%;
  display: flex;
  align-items: center;
}
.back-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #b1b1b1;
  font-size: 16px;
  font-family: "Lato";
  font-weight: bold;
  width: 100%;
  height: 7vh;
  display: flex;
  align-items: center;
}
.back-btn i {
  margin-right: 8px;
  display: flex;
  align-items: center;
}
.icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.detail_name {
  font-family: "Lato";
  font-weight: 600;
  font-size: large;
  color: #3c92b1;
  margin-bottom: 2vh;
}
.details-row {
  display: flex;
  flex-direction: row;
}
.details-row .address,
.details-row .icon,
.details-row .info {
  color: #b1b1b1;
}
.details {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.tabsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 24px 0px 24px;
  border-bottom: 1px solid #dedede;
  width: 100%;
}
.tab {
  font-family: "Lato";
  height: 7vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  color: gray;
  cursor: pointer;
}
.active-tab {
  color: black;
}
.active-tab::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3px;
  background-color: #3c92b1;
}
.contentContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 24px;
  width: 100%;
  display: none;
}
.active-content {
  display: block;
}
.contentBox {
  font-family: "Lato";
  background: #f8f8fb;
  padding: 16px;
  border-radius: 10px;
  margin-bottom: 16px;
}
.boxTitle {
  color: #3c92b1;
}
.boxData {
  color: #3c92b1;
  font-size: 48px;
  color: black;
}
.boxBody {
  font-size: 16px;
  color: #8f8f8f;
  font-weight: 400;
}

#details {
  animation: popin 1s;
}
@keyframes popin {
  0% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0px);
  }
}
@media only screen and (max-height: 1024px) and (max-width: 768px) {
  .titlerow {
    position: sticky;
    top: 40vh;
    background-color: #051115;
  }
  .sidemenu {
    z-index: 0;
    overflow: unset;
  }
}
