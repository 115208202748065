/*for blockchainSVG */
#shadows {
  animation: shadow-in 3s ease-in-out infinite;
}

@keyframes shadow-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

#balls {
  animation: balls 3s infinite linear;
}
@keyframes balls {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*for blockchainSVG */

#solution {
  animation: gradiantChain 3s ease-in-out forwards;
}

@keyframes gradiantChain {
  0% {
    background: linear-gradient(
      278.56deg,
      #050f13 24.63%,
      #09181e 62.97%,
      #0d232c 71.02%,
      #163a48 78.59%,
      rgba(28, 72, 90, 0.89) 81.86%,
      #2d7490 88.66%,
      #3e9ec4 93.45%
    );
    opacity: 0.2;
  }
  20% {
    background: linear-gradient(
      278.56deg,
      #050f13 24.63%,
      #09181e 62.97%,
      #0d232c 71.02%,
      #163a48 78.59%,
      rgba(28, 72, 90, 0.89) 81.86%,
      #2d7490 88.66%,
      #3e9ec4 93.45%
    );
    opacity: 0.2;
  }
  40% {
    background: linear-gradient(
      278.56deg,
      #050f13 24.63%,
      #09181e 62.97%,
      #0d232c 71.02%,
      #163a48 78.59%,
      rgba(28, 72, 90, 0.89) 81.86%,
      #2d7490 88.66%,
      #3e9ec4 93.45%
    );
    opacity: 0.4;
  }
  60% {
    background: linear-gradient(
      278.56deg,
      #050f13 24.63%,
      #09181e 62.97%,
      #0d232c 71.02%,
      #163a48 78.59%,
      rgba(28, 72, 90, 0.89) 81.86%,
      #2d7490 88.66%,
      #3e9ec4 93.45%
    );
    opacity: 0.6;
  }
  80% {
    background: linear-gradient(
      278.56deg,
      #050f13 24.63%,
      #09181e 62.97%,
      #0d232c 71.02%,
      #163a48 78.59%,
      rgba(28, 72, 90, 0.89) 81.86%,
      #2d7490 88.66%,
      #3e9ec4 93.45%
    );
    opacity: 0.8;
  }
  100% {
    background: linear-gradient(
      278.56deg,
      #050f13 24.63%,
      #09181e 62.97%,
      #0d232c 71.02%,
      #163a48 78.59%,
      rgba(28, 72, 90, 0.89) 81.86%,
      #2d7490 88.66%,
      #3e9ec4 93.45%
    );
    opacity: 1;
  }
}
